import { Injectable, inject, signal } from '@angular/core';
import { UNDERSCORE_DIVIDER } from '@lf/matrix';
import { StrategyItem, StrategyMatrixWithPresentValues, UpdateStrategyMatrix } from '@type/strategy.type';
import { Observable, map, of, tap } from 'rxjs';
import { StrategyApiService } from '../strategy-api/strategy-api.service';
import { Status } from '@enums/item-status.enum';

@Injectable()
export class StrategyStateService {
  private startegyApi = inject(StrategyApiService);

  private readonly matrixMap = new Map<string, StrategyMatrixWithPresentValues>();

  strategy = signal<StrategyItem | null>(null);

  /**
   * @description structure such as {
        "94_98_102_105_108": {
          "3": 23
        }
      }
   */
  strategyChanges: Record<string, Record<string, number>> = {};

  /**
   * @description Get matrix from cache or load from backend
   * @param strategyId number
   * @param path number[]
   * @returns StrategyMatrixWithPresentValues
   */
  getMatrix(strategyId: number, path: number[]): Observable<StrategyMatrixWithPresentValues> {
    const key = path.join(UNDERSCORE_DIVIDER);
    const strategyMatrixWithPresentValues = this.matrixMap.get(key);

    if (strategyMatrixWithPresentValues) return of(strategyMatrixWithPresentValues);
    else
      return this.startegyApi.getMatrix(strategyId, path).pipe(
        map((res) => {
          res.matrix = this.startegyApi.items.reduce(
            (a, c) => Object.assign(a, { [c.id]: (res.matrix || {})[c.id] || 0 }),
            {},
          );
          return res;
        }),
        tap((res) => this.matrixMap.set(key, res)),
      );
  }

  convertStateToStategyMatrix(state: Record<string, Record<string, number>>): UpdateStrategyMatrix {
    return Object.keys(state).reduce((matrix, branchPathString) => {
      const branch = branchPathString.split(UNDERSCORE_DIVIDER).map((n) => Number(n));
      const data = state[branchPathString];

      Object.entries(data).forEach(([itemId, count]) => {
        const item_id = Number(itemId);
        matrix.push({ item_id, count, branch });
      });

      return matrix;
    }, [] as UpdateStrategyMatrix);
  }

  clearMatrixMap() {
    this.matrixMap.clear();
  }

  clearStrategyChanges() {
    this.strategyChanges = {};
  }

  canEditAndSaveStrategy() {
    return ![Status.Active, Status.Expired].includes(this.strategy()?.status.status as Status);
  }
}
